<template>
  <div class="page">
    <Slide noOverlay right closeOnNavigation>
      <a v-scroll-to="'#kdo_smo'">
        <span>Kdo smo</span>
      </a>
      <a v-scroll-to="'#storitve'">
        <span>Storitve</span>
      </a>
      <a v-scroll-to="'#kontakt'">
        <span>Kontakt</span>
      </a>
    </Slide>

    <hero></hero>
    <about id="kdo_smo"></about>
    <servicesOuter id="storitve"></servicesOuter>
    <foot id="kontakt"></foot>
  </div>
</template>

<script>
import hero from "@/views/sections/hero.vue";
import about from "@/views/sections/about.vue";
import servicesOuter from "@/views/sections/servicesOuter.vue";
import foot from "@/views/sections/foot.vue";

import { Slide } from "vue-burger-menu";

export default {
  name: "Home",

  components: { hero, about, servicesOuter, foot, Slide },
};
</script>

<style lang="scss">
.page {
}
</style>