<template>
  <div class="outer" :style="'padding-bottom:' + calcHeight + 'px'">
    <div class="about">
      <b-container>
        <img :src="require('@/assets/img/line.svg')" />
        <h3>Kdo smo?</h3>
        <p>
          Smo skupina izkušenih posameznikov z različnih področij in
          komplementarnimi znanji, ki nas združujeta močni želja in vizija
          prispevati k boljši družbi, okolju in javnemu dobremu. Zato smo
          vzpostavili prvo slovensko specializirano marketinško agencijo za
          družbeno odgovorne projekte in socialni marketing.
        </p>
        <p>
          Verjamemo v moč skupinskega partnerskega ustvarjanja, pri čemer se
          vedno povezujemo z različnimi profili posameznikov. S komunikacijskimi
          strokovnjaki, strategi, analitiki, programerji, oblikovalci in
          najboljšimi kreativci na eni strani ter izkušenimi strokovnjaki in
          nevladnimi organizacijami s posameznih področij družbenih in okoljskih
          problematik na drugi strani.
        </p>
        <p>
          Delovno skupino vedno sestavimo na podlagi zahtev in potreb
          posameznega projekta.
        </p>
        <b-row>
          <b-col offset="6" cols="6" id="imgCol">
            <div class="img-container">
              <img
                id="aboutImg"
                class="about-img xs-down-hide"
                :src="require('@/assets/img/about.jpg')"
                :srcset="require('@/assets/img/about@2x.jpg')"
              />
              <img
                id="aboutImg"
                class="about-img xs-up-hide"
                :src="require('@/assets/img/about-mobile.jpg')"
                :srcset="require('@/assets/img/about-mobile@2x.jpg')"
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      calcHeight: 0,
    };
  },

  mounted() {
    this.setPad();
    window.addEventListener("resize", this.setPad);
  },

  methods: {
    setPad() {
      var divider = window.innerWidth > 551 ? 2 : 0.94;
      this.calcHeight = ((window.innerWidth / divider) * 447) / 960;
    },
  },
};
</script>

<style lang="scss" scoped>
.outer {
  width: 100vw;
  padding: 0;
  margin: 0;
  margin-top: 6.5rem;
}

.about {
  background-color: $black;
  padding: 0;
  margin: 0;
  padding: 4rem 0 2rem;

  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;

  @include mobile {
    padding: 3rem 1rem 2rem;
  }
}

h3,
p {
  color: $white;
}

.img-container {
  position: relative;
  margin-top: 1rem;

  @include desktop {
    margin-top: 2rem;
  }
}

.about-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 50vw;
}
</style>