export const services = [
    {
        title: "Integrirane-celostne-rešitve",
        text:
            "K projektu najraje pristopimo že na čistem začetku, saj so tako naše rešitve kar najbolj učinkovite. S skupnimi močmi bomo zastavili vse od strateško-taktične zasnove projekta do kreativne in oblikovalske izvedbe z vrednotenjem kazalnikov in učinka.",
        tags: [
            "Integrirane družbeno odgovorne komunikacijske kampanje",
            "Cause-related marketing",
            "Socialni marketing",
        ],
    },
    {
        title: "Strategije",
        text:
            "Premišljena strategija, ki temelji na analizi javnosti in značilnostih družbene oz. okoljske problematike, je temelj za ustvarjanje učinkovitih in relevantnih akcij in kampanj.",
        tags: [
            "Komunikacijske in kreativne strategije",
            "Analiza kazalnikov učinkovitosti",
            "Strateško planiranje akcij in kampanj",
            "Svetovanje",
        ],
    },
    {
        title: "Grafično-oblikovanje",
        text:
            "Nekateri projekti so v začetni fazi in za začetek potrebujejo funkcionalno celostno podobo, drugi projekti pa že tik pred izvedbo in potrebujejo samo še obrtno znanje umetniškega vodenja in oblikovanja. V obeh primerih bomo poskrbeli, da bo oblikovanje sledilo funkcijam sporočilnosti, opaznosti in učinkovitosti.",
        tags: [
            "Celostne grafične podobe",
            "Logotipi",
            "Embalaže",
            "Tiskani materiali",
            "Digitalni formati in spletne strani",
        ],
    },
    {
        title: "Digitalne-rešitve",
        text:
            "Nastop na digitalnih kanalih je v današnjem času nujen, hkrati pa omogoča natančno ciljanje, merjenje in analizo akcij ter organsko širjenje sporočil.",
        tags: [
            "Načrtovanje, zasnova in oblikovanje spletnih strani",
            "Načrtovanje in izvedba digitalnih kampanj",
            "Upravljanje družbenih omrežij",
            "Tekstopisje",
        ],
    },
    {
        title: "Odnosi z-javnostmi",
        text:
            "Odnosi z javnostmi so še posebej pomembni in ključni za večji doseg, prepričljivost in končni uspeh družbeno odgovornih kampanj in socialnega marketinga. Zato bomo z natančnim planiranjem in plasiranjem ter relevantnimi kreativnimi taktikami poskrbeli za odmev projekta v medijih in med različnimi javnostmi.",
        tags: ["Svetovanje", "Pisanje PR vsebin", "Distribucija PR vsebin"]
    },
    {
        title: "Produkcija",
        text:
            "Ustvarjanje družbeno odgovornih kampanj in socialnega marketinga zahteva premišljen razrez sredstev, ki jih na eni strani namenimo za kreacijo materialov in na drugi za medijsko plasiranje sporočil. Zato ponujamo stroškovno učinkovito kakovostno produkcijo različnih formatov »na ključ«.",
        tags: [
            "Fotografiranje",
            "Video produkcija",
            "Foto in video postprodukcija",
        ],
    },
]