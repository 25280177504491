<template>
  <b-container class="hero">
    <img
      class="top-img"
      :src="require('@/assets/img/ado-txt.svg')"
      alt="Agencija za družbeno odgovornost"
    />
    <br />
    <img class="logo-img" :src="require('@/assets/img/ado.svg')" alt="ADO" />
    <b-row align-v="center" class="cust-margin">
      <b-col cols="10" md="6" class="text-left cust-mb">
        <img :src="require('@/assets/img/line.svg')" />
        <h3>Moč dobrih komunikacij</h3>
        <p>
          je več kot relevantna strategija in vrhunska izvedba.
          Moč dobrih komunikacij je v spreminjanju sveta na bolje.
        </p>
      </b-col>
      <b-col cols="12" md="6" id="imgCol" :style="'height:' + calcHeight + 'px'">
        <b-row>
          <b-col cols="6" offset="6" offset-md="0" md="12">
            <div class="img-container">
              <img
                id="heroImg"
                class="hero-img xs-down-hide"
                :src="require('@/assets/img/hero.jpg')"
                :srcset="require('@/assets/img/hero@2x.jpg')"
              />
              <img
                id="heroImg"
                class="hero-img xs-up-hide"
                :src="require('@/assets/img/hero-mobile.jpg')"
                :srcset="require('@/assets/img/hero-mobile@2x.jpg')"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="10" md="6" offset-md="6" class="text-left cust-mt">
        <h3>Kaj delamo? / Kako delamo?</h3>
        <p>Spreminjanje sveta na bolje je zahteven proces, ki najprej potrebuje poglobljeno razumevanje družbenih in okoljskih problematik, zatem pa strokovna znanja s področja psihologije in komuniciranja. Mi združujemo oboje.</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      calcHeight: 0,
    };
  },

  mounted() {
    this.setHeroHeight();
    window.addEventListener("resize", this.setHeroHeight);
  },

  methods: {
    setHeroHeight() {
      var divider = window.innerWidth > 551 ? 2 : 0.94;
      this.calcHeight = ((window.innerWidth / divider) * 447) / 960;
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  text-align: center;
}

.cust-margin {
  margin-top: 7rem;

  @include desktop {
    margin-top: 4rem;
  }
}

.cust-mb {
  @include mobile {
    margin-bottom: 5rem;
  }
}

.cust-mt {
  margin-top: 2rem;
}

.top-img {
  margin: auto;
  width: 30%;
  max-width: 208px;
}

.logo-img {
  margin: auto;
  width: 50%;
  max-width: 417px;
  margin-top: 6rem;

  @include desktop {
    margin-top: 8rem;
  }
}

.row {
  @include mobile {
    padding: 0 1rem;
  }
}

.img-container {
  width: 100%;
  position: relative;

  .hero-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 50vw;
  }
}
</style>