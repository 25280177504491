import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

// BootstrapVue
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

// scrollTo
var VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 800,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
})

// import swiper
import 'swiper/swiper-bundle.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'

Vue.use(VueAwesomeSwiper /* { default options with global component } */)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
