<template>
  <div class="services">
    <b-container>
      <img :src="require('@/assets/img/line.svg')" />
      <h3>Storitve</h3>
      <br />
      <div class="card-list">
        <swiper-slide v-for="service in localServices" :key="service.title">
          <service :title="service.title" :text="service.text" :tags="service.tags"></service>
        </swiper-slide>
      </div>
    </b-container>
  </div>
</template>

<script>
import service from "@/components/service";
import { services } from "@/data/services.js";

export default {
  data() {
    return {
      localServices: services,
    };
  },

  components: {
    service,
  },
};
</script>

<style lang="scss" scoped>
.services {
  margin-top: 12rem;
}

.service {
  margin: auto;
}

.card-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 30%;
  flex-basis: 30%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  > div {
    -webkit-box-flex: 270px;
    -ms-flex: 270px;
    flex: 270px;
    margin: 1rem;
  }
}

/* 
@supports (display: grid) {
  .card-list {
    display: grid;
    display: -ms-grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, minmax(170px, 1fr));
    -ms-grid-columns: minmax(170px, 1fr) 1rem minmax(170px, 1fr) 1rem
      minmax(170px, 1fr);
    > div {
      margin: 0;
    }
  }
} */
</style>