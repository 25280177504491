<template>
  <div>
    <services class="desktop"></services>
    <swipe class="mobile"></swipe>
  </div>
</template>

<script>
import services from "@/views/sections/services.vue";
import swipe from "@/views/sections/test.vue";

export default {
  components: { services, swipe },
};
</script>

<style lang="scss">
</style>