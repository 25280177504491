<template>
  <div class="service">
    <div class="title">
      <div v-for="word in title.split('-')" :key="word">
        <h2>{{word}}</h2>
        <br />
      </div>
    </div>
    <div class="text mt-3 mb-2">
      <p>{{text}}</p>
    </div>
    <div class="tags" v-for="tag in tags" :key="tag">
      <p>{{tag}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "text", "tags"],
};
</script>

<style lang="scss" scoped>
.service {
  text-align: left;
  width: 230px;
  max-width: 100%;

  @include xs {
    width: 273px;
  }
}

.text {
  padding: 0 1rem;

  p {
    line-height: 1.26;
  }
}

h2 {
  font-size: 2rem;
  font-family: $sourceBold;
  background-color: $black;
  color: $white;
  display: inline;
  line-height: 1.5;
  padding: 0 0.7rem;
}

.tags {
  p {
    background-color: $yellow;
    display: inline-block;
    padding: 0.7rem 1.2rem;
    margin: 0.34rem 0;
    line-height: 1.25;
    font-family: $sourceSemiBold;

    @include desktop {
      padding: 0.15rem 1rem;
      font-family: $sourceBold;
    }
  }
}
</style>