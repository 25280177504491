<template>
  <b-container class="mt-5">
    <img :src="require('@/assets/img/line.svg')" />
    <h3>Storitve</h3>
    <br />
    <Slider
      animation="normal"
      v-model="sliderValue"
      :speed="400"
      :autoplay="false"
      :height="'auto'"
    >
      <SliderItem v-for="service in localServices" :key="service.title">
        <service :title="service.title" :text="service.text" :tags="service.tags"></service>
      </SliderItem>
      <div slot="loading">Nalagam ...</div>
    </Slider>
  </b-container>
</template>

<script>
import { Slider, SliderItem } from "vue-easy-slider";
import service from "@/components/service";
import { services } from "@/data/services.js";

export default {
  name: "App",
  components: {
    Slider,
    SliderItem,
    service,
  },
  data() {
    return {
      localServices: services,
      sliderValue: 0,
    };
  },
  methods: {
    changeIndex(index) {
      this.sliderValue = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper {
  text-align: center;
  margin: 0 2rem;

  @include xs {
    margin: 0 3rem;
  }
}

.service {
  margin: auto;
}

.slider-btn {
  background: $white !important;
}
</style>
